
import {
  defineComponent, onUnmounted, onMounted,
} from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import toggleScroll from '~/utils/toggleScroll';
import SplitView from '~/components/SplitView.vue';

export default defineComponent({
  name: 'WhatMakesFCModal',
  components: {
    FCImage,
    SplitView,
  },
  props: {
    product: {
      type: Object,
      default: () => ({
        title: '',
        image: {
          src: 'https://a.storyblok.com/f/152150/1304x872/dfa4995221/fc-trackers-in-quickwraps-001.jpg',
          alt: 'quickwraps with trackers',
        },
        splitViewList: [
          {
            title: '',
            description: '',
            image: {
              src: 'https://a.storyblok.com/f/152150/1304x872/dfa4995221/fc-trackers-in-quickwraps-001.jpg',
              alt: 'quickwraps with trackers',
            },
          },
        ],
      }),
      required: true,
    },
  },
  setup(_, context) {
    const closeModal = () => {
      toggleScroll(false);
      context.emit('close');
    };

    // TODO add this to useModal so do not have to add this to each modal
    onUnmounted(() => {
      toggleScroll(false);
    });

    onMounted(() => {
      toggleScroll(true);
    });

    function isOdd(index: number) {
      return index % 2 !== 0;
    }

    return {
      closeModal,
      isOdd,
    };
  },
});
